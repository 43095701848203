import React from "react";

function Placeholder() {
  return (
    <>
      <div className="placeholder">
        <div className="animated-background">
          <img src="/KCLLC_Logo.svg" alt="KCLLC Logo" />
        </div>
      </div>
    </>
  );
}

export default Placeholder;
